import React, { useEffect, useMemo } from 'react';
import { RootState } from 'store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getAdditionalCreditInformation,
  getCreditUsage,
} from 'store/actions/subscription.actions';
import BillingOverviewTab from './BillingOverviewTab';
import './index.scss';
import CreditUsageTab from './CreditUsageTab';
import BillingTabs from './BillingTabs';
import ManageCreditsTab from './ManageCreditsTab/index';

export default function Billing(): any {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const creditUsage = useSelector(
    (state: RootState) => state.subscription?.creditUsage
  );
  const availablePackages = useSelector(
    (state: RootState) => state.subscription?.availablePackages
  );
  const orgId = userDetails?.recruiter_detail?.organisation_id;
  useEffect(() => {
    if (orgId) {
      dispatch(getCreditUsage(orgId));
    }
  }, [dispatch, location.pathname, orgId]);
  useEffect(() => {
    if (!availablePackages) {
      dispatch(getAdditionalCreditInformation());
    }
  }, [dispatch, availablePackages]);
  const tabsData = useMemo(() => {
    const tabs = [
      {
        tabTitle: 'Overview',
        label: 'Overview',
        contentLink: '/billing',
        component: <BillingOverviewTab />,
      },
    ];
    if (creditUsage) {
      tabs.push({
        tabTitle: 'Credit Usage',
        label: 'Credit Usage',
        contentLink: '/billing/credit-history',
        component: <CreditUsageTab />,
      });
      tabs.push({
        tabTitle: 'Manage Credits',
        label: 'Manage Credits',
        contentLink: '/billing/manage-credits',
        component: <ManageCreditsTab />,
      });
    }
    return tabs;
  }, [creditUsage]);

  return (
    <div className="billing-page">
      <h2 className="mb4">Billing</h2>
      <BillingTabs tabsData={tabsData} trialBannerShow={false} />
    </div>
  );
}
