import { handleError } from 'handleError';
import { organizationFeaturesEmpty } from 'helpers/user';
import { GetCreditUsageQueryParams } from 'api/contract';
import store, { AppThunk } from '../store';
import {
  getSubscription as getSubscriptionApi,
  getCreditUsage as getCreditUsageApi,
  getCreditHistory as getCreditHistoryApi,
  getAdditionalCreditInformation as getAdditionalCreditInformationApi,
} from '../../api/subscription.api';
import {
  getSubscriptionStart,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  getCreditUsageSuccess,
  getCreditHistorySuccess,
  getAdditionalCreditInformationSuccess,
} from '../reducers/subscription';
import { refreshCurrentTokens } from './auth.actions';

export const getSubscription = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { subscription } = store.getState();
  if (subscription?.loading) {
    return;
  }
  dispatch(getSubscriptionStart());
  getSubscriptionApi(orgId)
    .then((data) => {
      dispatch(getSubscriptionSuccess(data.data));
      const planName = data?.data?.data?.subscription?.plan?.name;
      if (planName !== 'Enterprise') {
        // When a user first subscribes to a plan (Lite or Professional), the token will sometimes have an empty 'org_features' array
        // which allows the organization to access all features. This change refreshes the token if the org_features array is empty
        // and the organization plan is not Enterprise
        if (organizationFeaturesEmpty()) {
          store.dispatch(refreshCurrentTokens());
        }
      }
    })
    .catch((err) => {
      dispatch(getSubscriptionFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const getCreditUsage = (orgId: number): AppThunk => async (dispatch) => {
  getCreditUsageApi(orgId).then((data) => {
    dispatch(getCreditUsageSuccess(data.data));
  });
};

export const getAdditionalCreditInformation = (): AppThunk => async (
  dispatch
) => {
  getAdditionalCreditInformationApi().then((data) => {
    dispatch(getAdditionalCreditInformationSuccess(data.data));
  });
};

export const getCreditHistory = (
  params: GetCreditUsageQueryParams
): AppThunk => async (dispatch) => {
  getCreditHistoryApi(params).then((res) => {
    dispatch(getCreditHistorySuccess(res?.data?.data));
  });
};
