import React from 'react';
import './ManageCredits.scss';
import NoteBanner, { NoteType } from 'components/Shared/Banners/NoteBanner';
import AutoTopupConfig from './AutoTopupConfig';
import AdditionalCreditPackages from './AdditionalCreditPackages';

function ManageCreditsTab(): any {
  return (
    <div className="manage-credits-page">
      <NoteBanner
        type={NoteType.NOTE}
        message="Running out of credits will severely limit your account's
        functionality. To keep your account fully functional, make sure you
        always have available credits."
      />
      <AutoTopupConfig />
      <AdditionalCreditPackages />
    </div>
  );
}

export default ManageCreditsTab;
