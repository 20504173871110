import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdditionalCreditPackage,
  GetCreditUsageQueryParams,
} from 'api/contract';
import {
  SubscriptionResult,
  ChargeResult,
  CreditUsage,
  CreditHistory,
} from 'api/subscription.api';

interface SubscriptionState {
  subscription: SubscriptionResult | null;
  latest_charge: ChargeResult | null;
  creditUsage: CreditUsage | null;
  creditHistory: CreditHistory | null;
  creditHistoryQueryParams: GetCreditUsageQueryParams;
  availablePackages: AdditionalCreditPackage[] | null;
  hasPaymentMethod: boolean | null;
  card_last_4_digits: string | null;
  billing_email_address: string | null;
  loading: boolean;
  error: string | null;
}

interface SubscriptionResponse {
  data: {
    subscription: SubscriptionResult;
    latest_charge: ChargeResult | null;
  };
}

interface CreditUsageResponse {
  data: {
    subscription_credits: number;
    subscription_credits_used: number;
    remaining_additional_credits: number;
  };
}

const initialState: SubscriptionState = {
  subscription: null,
  creditUsage: null,
  creditHistory: {},
  availablePackages: null,
  hasPaymentMethod: null,
  card_last_4_digits: null,
  billing_email_address: null,
  creditHistoryQueryParams: {
    perPage: 20,
    page: 1,
    orderDirection: 'desc',
    orderBy: 'Date',
  },
  latest_charge: null,
  loading: null,
  error: null,
};

const subscriptionReducer = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    updateCreditHistoryQueryParams: (
      state,
      action: PayloadAction<GetCreditUsageQueryParams>
    ) => {
      state.creditHistoryQueryParams = {
        ...state.creditHistoryQueryParams,
        ...action.payload,
      };
    },
    getSubscriptionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSubscriptionSuccess: (
      state,
      action: PayloadAction<SubscriptionResponse>
    ) => {
      state.subscription = action.payload.data?.subscription;
      state.latest_charge = action.payload.data?.latest_charge;
      state.loading = false;
    },
    getSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    getCreditUsageSuccess: (
      state,
      action: PayloadAction<CreditUsageResponse>
    ) => {
      state.creditUsage = action.payload.data;
    },
    getCreditHistorySuccess(state, action: PayloadAction<CreditHistory>) {
      state.creditHistory = action.payload;
    },
    getAdditionalCreditInformationSuccess(
      state,
      action: PayloadAction<{
        packages: AdditionalCreditPackage[];
        has_payment_method: boolean;
        last4: string | null;
        billing_email_address: string;
      }>
    ) {
      state.availablePackages = action.payload.packages;
      state.hasPaymentMethod = action.payload.has_payment_method;
      state.card_last_4_digits = action.payload.last4;
      state.billing_email_address = action.payload.billing_email_address;
    },
  },
});

export const {
  updateCreditHistoryQueryParams,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  getSubscriptionStart,
  getCreditUsageSuccess,
  getCreditHistorySuccess,
  getAdditionalCreditInformationSuccess,
} = subscriptionReducer.actions;
export default subscriptionReducer.reducer;
