import React from 'react';

export enum NoteType {
  INFO = 'info',
  NOTE = 'note',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}
export interface NoteBannerProps {
  type: NoteType;
  message: string;
}

const noteTypeStyles: Record<
  NoteType,
  { color: string; backgroundColor: string }
> = {
  [NoteType.INFO]: {
    color: '#0d6efd',
    backgroundColor: '#e7f3ff',
  },
  [NoteType.NOTE]: {
    color: 'white',
    backgroundColor: '#5a2eb3',
  },
  [NoteType.WARNING]: {
    color: '#fd7e14',
    backgroundColor: '#fff4e5',
  },
  [NoteType.ERROR]: {
    color: '#dc3545',
    backgroundColor: '#ffe7e9',
  },
  [NoteType.SUCCESS]: {
    color: '#198754',
    backgroundColor: '#e6f4ea',
  },
};

function NoteBanner({ type, message }: NoteBannerProps): React.ReactElement {
  return (
    <div className="custom-banner" style={noteTypeStyles[type]}>
      <div className="text-content">
        <p>{message}</p>
      </div>
    </div>
  );
}

export default NoteBanner;
